import axios, { AxiosError } from 'axios';

import { PageViewType } from '../common/types/enums';
import { blockRegistryApiEndpoint } from '../config/endpoints';
import { store } from '../store/store';

export interface ICreateBlockDetailResponse {
  blockId: string;
}

export async function createBlockForClientApi(request: Record<string, string | string[]>) {
  try {
    const response = await axios.post<ICreateBlockDetailResponse>(
      `${blockRegistryApiEndpoint}/block-registry/create-block`,
      {
        ...request,
        clientId: store.getState().authState.selectedAuthProfile.ClientId,
      },
    );

    return response.data;
  } catch (error) {
    if ((error as AxiosError)?.response?.data) {
      throw (error as AxiosError).response.data;
    }
    throw error;
  }
}

interface IListBlocksForClientResponse {
  blockList: Array<{
    blockId: string;
    blockName: string;
    blockCategory: string;
    blockStatus: string;
    blockType: string;
    createdAt: number;
    createdBy: string;
    updatedBy: string;
  }>;
  lastEvaluatedKey: string;
}

interface IListBlocksForClientRequest {
  pageSize?: number;
  lastEvaluatedKey?: string;
}

export async function listBlocksForClientApi(request: IListBlocksForClientRequest) {
  const response = await axios.post<IListBlocksForClientResponse>(
    `${blockRegistryApiEndpoint}/block-registry/list-blocks-for-client`,
    { ...request, clientId: store.getState().authState.selectedAuthProfile.ClientId },
  );
  return response.data;
}

interface IListBlocksRequest {
  pageSize?: number;
  lastEvaluatedKey?: string;
}

interface IListBlocksResponse {
  blockList: Array<{
    blockId: string;
    blockName: string;
    blockCategory: string;
    blockSummary: string;
    tags: string[];
    clientId: string;
  }>;
  totalResults: number;
}

export async function listBlocksApi(request: IListBlocksRequest) {
  const response = await axios.post<IListBlocksResponse>(`${blockRegistryApiEndpoint}/block-registry/list-blocks`, {
    ...request,
    pageSize: 1000,
    devTeamId: store.getState().authState.selectedAuthProfile.ClientId,
  });
  return response.data;
}

export interface IGetBlockRequest {
  blockId: string;
  clientId?: string;
  pageViewType: PageViewType;
}

interface IGetBlockResponse {
  blockDetail: {
    blockId: string;
    blockName: string;
    blockCategory: string;
    blockStatus: string;
    blockType: string;
    summary: string;
    emailAddress: string;
    tags: string[];
    ownerClientId: string;
    createdAt: number;
    createdBy: string;
    updatedBy: string;
  };
}

export async function getBlockApi(request: IGetBlockRequest) {
  try {
    const response = await axios.post<IGetBlockResponse>(`${blockRegistryApiEndpoint}/block-registry/get-block`, {
      ...request,
    });

    return response.data;
  } catch (error) {
    if ((error as AxiosError)?.response?.data) {
      throw (error as AxiosError).response.data;
    }
    throw error;
  }
}

export interface IListBlockArtifactsRequest {
  blockId: string;
  clientId?: string;
  pageViewType: PageViewType;
}

interface IListBlockArtifactsResponse {
  artifactList: Array<{
    version: string;
    artifactStatus: string;
    updatedAt: number;
    updatedBy?: string;
  }>;
}

export async function listBlockArtifactsApi(request: IListBlockArtifactsRequest) {
  try {
    const response = await axios.post<IListBlockArtifactsResponse>(
      `${blockRegistryApiEndpoint}/block-registry/list-block-artifacts`,
      {
        ...request,
      },
    );

    return response.data;
  } catch (error) {
    if ((error as AxiosError)?.response?.data) {
      throw (error as AxiosError).response.data;
    }
    throw error;
  }
}

export interface IGetBlockPipelineRequest {
  blockId: string;
}

interface IGetBlockPipelineResponse {
  pipeline: {
    pipelineStatus: string;
    awsAccountId: string;
    codePipelineName: string;
    codeRepositoryName: string;
    sdkAccessRoleArn: string;
    region: string;
    createdAt: number;
    createdBy: string;
    updatedBy: string;
  };
  artifactBuildInfo?: {
    buildStatus: string;
    buildTimestamp: number;
  };
}

export async function getBlockPipelineApi(request: IGetBlockPipelineRequest) {
  try {
    const response = await axios.post<IGetBlockPipelineResponse>(
      `${blockRegistryApiEndpoint}/block-registry/get-block-pipeline`,
      {
        ...request,
        clientId: store.getState().authState.selectedAuthProfile.ClientId,
      },
    );

    return response.data;
  } catch (error) {
    if ((error as AxiosError)?.response?.data) {
      throw (error as AxiosError).response.data;
    }
    throw error;
  }
}

interface IUpdateBlockArtifactRequest {
  blockId: string;
  version: string;
  artifactStatus: string;
}

interface IUpdateBlockArtifactResponse {
  artifactStatus: string;
}

export async function updateBlockArtifactApi(request: IUpdateBlockArtifactRequest) {
  try {
    const response = await axios.post<IUpdateBlockArtifactResponse>(
      `${blockRegistryApiEndpoint}/block-registry/update-block-artifact`,
      {
        ...request,
        clientId: store.getState().authState.selectedAuthProfile.ClientId,
      },
    );

    return response.data;
  } catch (error) {
    if ((error as AxiosError)?.response?.data) {
      throw (error as AxiosError).response.data;
    }
    throw error;
  }
}

export interface IGetBlockArtifactAssetRequest {
  blockId: string;
  version: string;
  clientId?: string;
  pageViewType: PageViewType;
}

export interface IGetBlockArtifactAssetResponse {
  artifactAssetUrl: string;
}

export async function getBlockArtifactAssetApi(request: IGetBlockArtifactAssetRequest) {
  try {
    const response = await axios.post<IGetBlockArtifactAssetResponse>(
      `${blockRegistryApiEndpoint}/block-registry/get-block-artifact-asset`,
      {
        ...request,
      },
    );

    return response.data;
  } catch (error) {
    if ((error as AxiosError)?.response?.data) {
      throw (error as AxiosError).response.data;
    }
    throw error;
  }
}
