import { Container, Header, SpaceBetween } from '@cloudscape-design/components';
import { DropdownStatusProps } from '@cloudscape-design/components/internal/components/dropdown-status/interfaces';
import { SelectProps } from '@cloudscape-design/components/select';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { CODE_EDITOR_I18N_STRINGS } from '../../../../common/constants/string-constants';
import { ArtifactStatus, LoadingStatus } from '../../../../common/types/enums';
import { IFunctionConfigMap, IListBlockArtifactsData } from '../../../../common/types/interfaces';
import { getBlockTagsListForSelection, loadAce } from '../../../../common/utils';
import { CheckboxFormField } from '../../../../components/polaris/CheckboxFormField';
import { CodeEditorFormField } from '../../../../components/polaris/CodeEditorFormField';
import InputFormField from '../../../../components/polaris/InputFormField';
import MultiselectFormField from '../../../../components/polaris/MultiselectFormField';
import SelectFormField from '../../../../components/polaris/SelectFormField';
import TextareaFormField from '../../../../components/polaris/TextAreaFormField';
import { selectListBlockArtifactsResponse } from '../../../../slices/BlockDetailSlice';
import { selectGetCapabilityResponse } from '../../../../slices/CapabilityDetailSlice';
import {
  CAPABILITY_HANDLER,
  CAPABILITY_INPUT,
  CAPABILITY_INSTANCE,
  CAPABILITY_NAME,
  CAPABILITY_OUTPUT,
  CAPABILITY_SUMMARY,
  HAS_NO_CAPABILITY_INPUT,
  HAS_NO_CAPABILITY_INSTANCE,
  HAS_NO_CAPABILITY_OUTPUT,
  MIN_BLOCK_ARTIFACT_VERSION,
  TAGS,
} from '../constants/form-constants';

function listBlockArtifactsOptions(
  artifactsData: IListBlockArtifactsData[],
  currentMinArtifactVersion: string,
): SelectProps.Options {
  let isCurrentVersionTraversed = false;

  return artifactsData
    .slice()
    .reverse()
    .filter((artifactData) => {
      if (artifactData.artifactStatus !== ArtifactStatus.PUBLISHED) {
        return false;
      }

      if (artifactData.version === currentMinArtifactVersion) {
        isCurrentVersionTraversed = true;
      }
      return isCurrentVersionTraversed;
    })
    .map((artifactData): SelectProps.Option => {
      return {
        label: artifactData.version,
        value: artifactData.version,
      };
    });
}

export default function MyBlockFunctionEditContainer() {
  const [ace, setAce] = useState({});
  const [loading, setLoading] = React.useState(true);
  const getCapabilityData = useSelector(selectGetCapabilityResponse);
  const listBlockArtifactsData = useSelector(selectListBlockArtifactsResponse);

  React.useEffect(() => {
    loadAce()
      .then((ace) => setAce(ace))
      .finally(() => setLoading(false));
  }, []);

  const { capabilityDetail } = getCapabilityData.data;
  const capabilityConfigMap = { ...(capabilityDetail.capabilityConfigMap as IFunctionConfigMap) };

  function listBlockArtifactsDropdownStatusType(): DropdownStatusProps.StatusType {
    if (listBlockArtifactsData.status === LoadingStatus.PENDING) {
      return 'loading';
    } else if (listBlockArtifactsData.status === LoadingStatus.SUCCESS) {
      return 'finished';
    }
    return 'error';
  }

  return (
    <SpaceBetween direction="vertical" size="xl">
      <Container header={<Header variant="h2">Basic information</Header>}>
        <SpaceBetween direction="vertical" size="l">
          <InputFormField
            name={CAPABILITY_NAME}
            label="Name"
            description="Provide a descriptive name for the function."
            ariaRequired={true}
            defaultValue={capabilityDetail.capabilityName}
          />
          <InputFormField
            name={CAPABILITY_HANDLER}
            label="Resource name"
            defaultValue={capabilityConfigMap.handler.functionHandler}
            disabled={true}
          />
          <MultiselectFormField
            name={TAGS}
            label={
              <span>
                Tags <i>- optional</i>{' '}
              </span>
            }
            description="Add tags to help others find the function more easily."
            placeholder="Select tag(s)"
            keepOpen={true}
            options={getBlockTagsListForSelection()}
            defaultValue={capabilityDetail.tags}
          />
          <TextareaFormField
            name={CAPABILITY_SUMMARY}
            label={
              <span>
                Description <i>- optional</i>{' '}
              </span>
            }
            description="Add a brief description for the function."
            constraintText="Maximum 500 characters"
            defaultValue={capabilityDetail.summary}
          />
          <SelectFormField
            name={MIN_BLOCK_ARTIFACT_VERSION}
            label="Minimum supported block executable version"
            description={'Changing the minimum supported block executable version will update the capability version'}
            control={null}
            ariaRequired={true}
            placeholder="Select a block executable"
            options={listBlockArtifactsOptions(listBlockArtifactsData.data, capabilityDetail.minArtifactVersion)}
            loadingText="Loading block executables"
            statusType={listBlockArtifactsDropdownStatusType()}
            recoveryText="Unable to fetch block executables. Please try again."
            empty="No block executables"
            errorText="Unable to fetch block executables. Please try again."
          />
        </SpaceBetween>
      </Container>
      <Container header={<Header variant="h2">Input</Header>}>
        <SpaceBetween direction="vertical" size="xl">
          <SpaceBetween direction="vertical" size="s">
            <CheckboxFormField
              name={HAS_NO_CAPABILITY_INSTANCE}
              label={<span>Instance</span>}
              description="Specify the instance details for the function."
              defaultValue={!capabilityConfigMap.input.instance}
              checkboxDescription="The function doesn’t have any instance."
            />
            <CodeEditorFormField
              name={CAPABILITY_INSTANCE}
              language="json"
              ace={ace}
              loading={loading}
              i18nStrings={CODE_EDITOR_I18N_STRINGS}
              onPreferencesChange={null}
              defaultValue={
                capabilityConfigMap.input.instance ? JSON.stringify(capabilityConfigMap.input.instance, null, 2) : null
              }
              editorContentHeight={100}
            />
          </SpaceBetween>
          <SpaceBetween direction="vertical" size="s">
            <CheckboxFormField
              name={HAS_NO_CAPABILITY_INPUT}
              label={<span>Input Parameters</span>}
              description="Specify the input arguments for the function."
              defaultValue={!capabilityConfigMap.input.params?.length}
              checkboxDescription="The function doesn’t have any input arguments."
            />
            <CodeEditorFormField
              name={CAPABILITY_INPUT}
              language="json"
              ace={ace}
              loading={loading}
              i18nStrings={CODE_EDITOR_I18N_STRINGS}
              onPreferencesChange={null}
              defaultValue={
                capabilityConfigMap.input.params?.length
                  ? JSON.stringify({ params: capabilityConfigMap.input.params }, null, 2)
                  : null
              }
            />
          </SpaceBetween>
        </SpaceBetween>
      </Container>
      <Container header={<Header variant="h2">Output</Header>}>
        <SpaceBetween direction="vertical" size="s">
          <CheckboxFormField
            name={HAS_NO_CAPABILITY_OUTPUT}
            label={<span>Output</span>}
            description="Specify the output arguments for the function."
            defaultValue={!capabilityConfigMap.output}
            checkboxDescription="The function doesn’t have any output arguments."
          />
          <CodeEditorFormField
            name={CAPABILITY_OUTPUT}
            language="json"
            ace={ace}
            loading={loading}
            i18nStrings={CODE_EDITOR_I18N_STRINGS}
            onPreferencesChange={null}
            defaultValue={capabilityConfigMap.output ? JSON.stringify(capabilityConfigMap.output, null, 2) : null}
          />
        </SpaceBetween>
      </Container>
    </SpaceBetween>
  );
}
