import { IAutomationStatusConfig } from '../interfaces/AutomationData';
import { AutomationStatus, AutomationType, ExecutionEnvironment, WindowsEC2SourceType } from '../types/enums';

export const AutomationStatusConfig: Record<AutomationStatus, IAutomationStatusConfig> = {
  DEVELOPMENT: {
    automationStatus: AutomationStatus.DEVELOPMENT,
    automationStatusDisplayText: 'Development',
    automationStatusBadgeProps: {
      color: 'grey',
    },
    updateAutomationStatusUpdateButtonText: 'Release to UAT',
    nextAutomationStatus: AutomationStatus.UAT,
    updateAutomationStatusUpdateButtonModalContent: 'The UAT version will be available on Workplace Assistant.',
    updateAutomationStatusUpdateButtonResponseMessage: 'You’ve released the UAT version successfully.',
  },
  UAT: {
    automationStatus: AutomationStatus.UAT,
    automationStatusDisplayText: 'UAT',
    automationStatusBadgeProps: {
      color: 'blue',
    },
    updateAutomationStatusUpdateButtonText: 'Release to Prod',
    nextAutomationStatus: AutomationStatus.PROD,
    updateAutomationStatusUpdateButtonModalContent: 'The Prod version will be synced with the current UAT version.',
    updateAutomationStatusUpdateButtonResponseMessage: 'You’ve released the latest version to Prod successfully.',
  },
  PROD: {
    automationStatus: AutomationStatus.PROD,
    automationStatusDisplayText: 'Prod',
    automationStatusBadgeProps: {
      color: 'green',
    },
  },
  MAINTENANCE: {
    automationStatus: AutomationStatus.MAINTENANCE,
    automationStatusDisplayText: 'Maintenance',
    automationStatusBadgeProps: {
      color: 'grey',
    },
    updateAutomationStatusUpdateButtonText: 'Start UAT again',
    nextAutomationStatus: AutomationStatus.POST_MAINTENANCE_UAT,
    updateAutomationStatusUpdateButtonModalContent:
      'New UAT versions will be available in Workplace Assistant and won’t be synced to Prod until you release to Prod again.',
    updateAutomationStatusUpdateButtonResponseMessage: 'You’ve released the latest version to UAT successfully.',
  },
  POST_MAINTENANCE_UAT: {
    automationStatus: AutomationStatus.POST_MAINTENANCE_UAT,
    automationStatusDisplayText: 'UAT',
    automationStatusBadgeProps: {
      color: 'blue',
    },
    updateAutomationStatusUpdateButtonText: 'Release to Prod',
    nextAutomationStatus: AutomationStatus.PROD,
    updateAutomationStatusUpdateButtonModalContent: 'The Prod version will be synced with the current UAT version.',
    updateAutomationStatusUpdateButtonResponseMessage: 'You’ve released the latest version to Prod successfully.',
  },
};

export const AutomationTypeDisplayNameMap: Record<AutomationType, string> = {
  [AutomationType.WINDOWS_ASSISTANT]: 'Console Application',
  [AutomationType.AUTO_DEV]: 'AutoDev Workflow',
  [AutomationType.CLOUD]: 'AWS State Machine',
  [AutomationType.CHAT_BASED_AGENT]: 'Chat Agent',
};

export const ExecutionEnvironmentDisplayNameMap: Record<ExecutionEnvironment, string> = {
  [ExecutionEnvironment.WINDOWS_DESKTOP]: 'Windows Desktop',
  [ExecutionEnvironment.WINDOWS_EC2]: 'Windows EC2',
  [ExecutionEnvironment.AWS_SFN]: 'AWS Step Functions',
  [ExecutionEnvironment.WORKPLACE_CHAT_SERVICE]: 'Workplace Chat Service',
};

export const AutomationTypeToExecutionEnvironmentMap: Record<AutomationType, ExecutionEnvironment[]> = {
  [AutomationType.WINDOWS_ASSISTANT]: [ExecutionEnvironment.WINDOWS_DESKTOP, ExecutionEnvironment.WINDOWS_EC2],
  [AutomationType.AUTO_DEV]: [ExecutionEnvironment.WINDOWS_DESKTOP, ExecutionEnvironment.WINDOWS_EC2],
  [AutomationType.CLOUD]: [ExecutionEnvironment.AWS_SFN],
  [AutomationType.CHAT_BASED_AGENT]: [ExecutionEnvironment.WORKPLACE_CHAT_SERVICE],
};

export const WindowsEC2SourceTypeDisplayNameMap: Record<WindowsEC2SourceType, string> = {
  [WindowsEC2SourceType.AWS_EC2_INSTANCE]: 'AWS EC2 Instance',
  [WindowsEC2SourceType.AWS_EC2_AMI]: 'AWS EC2 AMI',
};
