import { ArtifactPlatform, AutomationType, PipelineBuildStatus } from '../../../../common/types/enums';

type IPlatformDisplayInfo = {
  [key in AutomationType]: Array<{
    artifactPlatform: ArtifactPlatform;
    platformDisplayText: string;
    buildStatus?: PipelineBuildStatus;
    buildTimestamp?: number;
  }>;
};

export const PLATFORM_DISPLAY_MAP: IPlatformDisplayInfo = {
  [AutomationType.WINDOWS_ASSISTANT]: [
    {
      artifactPlatform: ArtifactPlatform.WIN_X64,
      platformDisplayText: 'x64',
    },
    {
      artifactPlatform: ArtifactPlatform.WIN_X86,
      platformDisplayText: 'x86',
    },
  ],
  [AutomationType.AUTO_DEV]: [
    {
      artifactPlatform: ArtifactPlatform.WIN_X64,
      platformDisplayText: 'x64',
    },
    {
      artifactPlatform: ArtifactPlatform.WIN_X86,
      platformDisplayText: 'x86',
    },
  ],
  [AutomationType.CLOUD]: [
    {
      artifactPlatform: ArtifactPlatform.STEP_FUNCTION,
      platformDisplayText: 'AWS Step Functions',
    },
  ],
  [AutomationType.CHAT_BASED_AGENT]: [
    {
      artifactPlatform: ArtifactPlatform.WORKPLACE_PLATFORM,
      platformDisplayText: 'Workplace Portal',
    },
  ],
};
